import { getAddress } from 'viem'
import memoize from 'lodash/memoize'
import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.POLYGON_ZKEVM]: 'polygonzkevm',
  [ChainId.BRNKC]: 'brnkc',
  [ChainId.ZKSYNC]: 'zksync',
}

const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token && mapping[token.chainId]) {
      if (token.chainId === 1) {
        return `https://smbswap.finance/images/1/tokens/${getAddress(token.address)}.png`
      }

      if (token.chainId === 56) {
        return `https://smbswap.finance/images/tokens/${getAddress(token.address)}.png`
      }
    }
    return null
  },
  (t) => `${t.chainId}#${t.address}`,
)

export const getTokenLogoURLByAddress = memoize(
  (address?: string, chainId?: number) => {
    if (chainId === 1) {
      return `https://smbswap.finance/images/${chainId}/tokens/${getAddress(address)}.png`
    }
    
    if (chainId === 56) {
      return `https://smbswap.finance/images/tokens/${getAddress(address)}.png`
    }
    return null
  },
  (address, chainId) => `${chainId}#${address}`,
)

export default getTokenLogoURL
